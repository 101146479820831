import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { estoqueStore, livroStore, userToolStore } from '@/store';
import _ from 'lodash';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('min_value', {
    getMessage: (field, value) => `${field} deve ser um valor maior ou igual a ${value}!`,
    validate: async (value, minimum) => value >= minimum,
});
let EstoqueCreate = class EstoqueCreate extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Data de movimentação', value: 'created_at' },
            { text: 'Quantidade movimentada', value: 'quantidade' },
            { text: 'Saldo em estoque', value: 'saldo' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Observação', value: 'observacao' },
        ];
        this.id = null;
        this.livro = null;
        this.estoque = [];
        this.search = '';
        this.selectedQuantidade = null;
        this.selectedObservacao = '';
        this.fazerEntrada = false;
        this.ajustarEstoque = false;
        this.loading = false;
    }
    get saldo() {
        if (this.estoque[0]) {
            return this.estoque[0].saldo;
        }
        else {
            return 0;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Estoque) {
            return userToolStore.userToolsIndexed.Estoque[0].allow_edit;
        }
    }
    async cancelarFazerEntrada() {
        this.fazerEntrada = false;
        this.selectedQuantidade = null;
        this.selectedObservacao = '';
    }
    async cancelarAjustarEstoque() {
        this.ajustarEstoque = false;
        this.selectedQuantidade = null;
        this.selectedObservacao = '';
    }
    async confirmarAjustarEstoque() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            return;
        }
        // @ts-ignore
        const payload = {
            saldo: this.selectedQuantidade,
            observacao: this.selectedObservacao,
            livro_id: this.id,
        };
        await estoqueStore.createAjusteEstoque(payload);
        this.estoque = await estoqueStore.getHistorico(this.id);
        this.cancelarAjustarEstoque();
    }
    async confirmarFazerEntrada() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            return;
        }
        // @ts-ignore
        const payload = {
            quantidade: this.selectedQuantidade,
            observacao: this.selectedObservacao,
            livro_id: this.id,
        };
        await estoqueStore.createEstoque(payload);
        this.estoque = await estoqueStore.getHistorico(this.id);
        this.cancelarFazerEntrada();
    }
    async showFazerEntrada() {
        this.fazerEntrada = true;
    }
    async showAjustarEstoque() {
        this.ajustarEstoque = true;
    }
    async mounted() {
        this.loading = true;
        // @ts-ignore
        this.id = this.$router.currentRoute.params.livro_id;
        this.livro = _.find(livroStore.livros, (item) => item.id == this.id);
        this.estoque = await estoqueStore.getHistorico(
        // @ts-ignore
        this.$router.currentRoute.params.livro_id);
        this.loading = false;
    }
};
EstoqueCreate = __decorate([
    Component({
        components: {
            EntidadeLinkComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], EstoqueCreate);
export default EstoqueCreate;
